// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from "react";
import styled from "styled-components";

const Btn = styled.button`
  -webkit-appearance: none;
  background-color: #fa6b91;
  padding: 16px 34px;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  outline: none;
  font-size: 18px;
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;
`;

const Input = styled.input`
  border: none;
  background-color: #111;
  border-bottom: 1px solid white;
  outline: none;
  margin-right: 18px;
  flex-grow: 1;
  color: white;

  @media (max-width: 600px) {
    margin-bottom: 20px;
    margin-right: 0;
    line-height: 2;
  }
`;

const Form = styled.form`
  width: 100%;
  margin-bottom: 0;
`;

const FormContents = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <Form
        onSubmit={this.submitForm}
        action="https://formspree.io/mwkenkgz"
        method="POST"
      >
        <FormContents>
          {status === "SUCCESS" ? (
            <p>Thanks! We'll reach back out soon.</p>
          ) : (
            <>
              <Input type="email" name="email" placeholder="Email address" />
              <Btn>Join Pilot</Btn>
            </>
          )}
        </FormContents>
        {status === "ERROR" && (
          <p style={{ marginBottom: 0, marginTop: 17 }}>
            Ooops! There was an error.
          </p>
        )}
      </Form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
