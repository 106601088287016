import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ProcessImage from "../images/modified-undraw-process.svg";
import MyForm from "../components/form.js";

const Logo = styled.div`
  font-size: 20px;
  font-weight: 800;
  font-family: "Nunito Sans", sans-serif;
`;

const Links = styled.div`
  margin-left: auto;
  display: flex;

  a {
    text-decoration: none;

    color: #111;
    font-size: 14px;
    margin-left: 20px;
    opacity: 0.8;

    &.strong {
      opacity: 0.9;
      font-weight: 600;
    }
  }
`;

const NavBar = styled.div`
  display: flex;
  padding-top: 30px;
  margin: 0 60px;

  @media (max-width: 600px) {
    & ${Logo} {
      font-size: 22px;
    }
    & ${Links} {
      display: none;
    }
    margin: 0 30px;
  }
`;

const HeroImage = styled.img`
  max-height: 100%;

  min-width: 0;
  padding: 60px 60px 60px 40px;

  max-width: 540px;
  margin-left: auto;

  @media (max-width: 1040px) {
    display: none;
  }
`;

const Item = styled.span`
  white-space: nowrap;

  @media (max-width: 500px) {
    white-space: unset;
  }
`;

const Hero = () => {
  return (
    <h1
      style={{
        opacity: 1,
      }}
    >
      <Item>
        <span style={{ color: "#fc6990" }}>Proactive Customer Success</span>
      </Item>{" "}
      <Item>for rapidly growing companies.</Item>
    </h1>
  );
};

const IndexPage = () => (
  <Layout>
    <SEO title="Quantifai" />
    <Pane>
      <TopSection>
        <NavBar>
          <Logo>Quantifai</Logo>
          <Links>
            <a href="#contact" className="drift-open-chat strong">
              Contact
            </a>
          </Links>
        </NavBar>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ zIndex: 2 }}>
            <Hero />
            <h2 style={{ fontWeight: 400, lineHeight: 1.4 }}>
              Quantifai uses machine learning to identify{" "}
              <span style={{ fontWeight: 600 }}>churn risks</span> and{" "}
              <span style={{ fontWeight: 600 }}>upsell opportunities</span>, so
              Customer Success Managers know who to reach out to and what to
              reach out about.
            </h2>
            <AlumSection>
              <SectionContents>
                <MyForm />
              </SectionContents>
            </AlumSection>
          </div>
          <HeroImage src={ProcessImage} style={{ zIndex: 1 }} />
          <StyledSVGContainer>
            <BG />
          </StyledSVGContainer>
        </div>
      </TopSection>
    </Pane>
    <Pane>
      <SecondSection>
        <div className="inner-section">
          <div className="inner-2-section">
            <div style={{ fontSize: 26, lineHeight: 1.4, color: "#394455" }}>
              Automated and actionable health scores—no manual work needed.
            </div>
            <div
              style={{
                marginTop: 35,
                opacity: 0.8,
                fontWeight: 400,
                color: "#475872",
              }}
            >
              Quantifai analyzes historical and real-time user data to let you
              know which customers are happy and which are at risk.
            </div>
          </div>
          <Screenshot>
            <img src="/healthscore.png" alt="health score" />
          </Screenshot>
        </div>
        <StyledSVGContainer>
          <BG2 />
        </StyledSVGContainer>
      </SecondSection>
    </Pane>
    <Pane>
      <ThirdSection>
        <div className="inner-section">
          <Screenshot>
            <img src="/highlights.png" alt="customer highlights" />
          </Screenshot>
          <div className="inner-2-section">
            <div style={{ fontSize: 26, lineHeight: 1.4, color: "#394455" }}>
              See the most important events in your user's journey.
            </div>
            <div
              style={{
                marginTop: 35,
                opacity: 0.8,
                fontWeight: 400,
                color: "#475872",
              }}
            >
              We surface relevant user behaviors so you can act on data instead
              of digging through it.
            </div>
          </div>
        </div>

        <StyledSVGContainer>
          <BG3 />
        </StyledSVGContainer>
      </ThirdSection>
    </Pane>
    <Pane>
      <FourthSection>
        <div className="level-1-container">
          <div className="level-2-container">
            <div className="about-us">
              <div>
                We're MIT + HubSpot alums helping CSMs become more effective.
              </div>
              <div style={{ marginTop: 20 }}>
                We're backed by investors who love CS as much as we do.
              </div>
            </div>

            <div className="pilot-form">
              <div style={{ marginBottom: 20 }}>
                Want to be a part of our pilot program?
              </div>
              <MyForm />
            </div>
          </div>

          <StyledSVGContainer>
            <BG4 />
          </StyledSVGContainer>
        </div>
      </FourthSection>
    </Pane>
  </Layout>
);

const Screenshot = styled.div`
  position: relative;
  display: flex;

  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 35px 2px rgba(0, 0, 0, 0.16);

  img {
    margin: 0;
    height: 75vh;
  }
  &:before {
    content: "";
    box-shadow: 0 0 0 10px white inset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 560px) {
    margin-left: 60px;
    margin-right: 60px;

    img {
      height: auto;
    }
  }
`;

const Pane = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`;

const Section = styled.div`
  padding: 0px 60px 1.35rem;
  margin: 0;
  color: hsla(0, 0%, 0%, 0.8);
`;

const SecondSection = styled.div`
  position: relative;
  height: 100vh;
  min-height: 540px;

  background-color: #f6fcfa;
  color: #111;
  display: flex;
  flex-direction: column;

  max-width: 1380px;
  width: 100%;

  &:before,
  &:after {
    height: 100%;
    content: "";
    position: absolute;
    width: 50vw;
    z-index: -1;
  }

  &:before {
    right: 100%;
    background-color: #f6fcfa;
  }
  &:after {
    left: 100%;
    background-color: rgb(214, 236, 246);
  }

  .inner-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 3;
  }

  .inner-2-section {
    margin-right: 140px;
    max-width: 370px;
    font-family: "Nunito Sans";
    margin-bottom: 60px;
  }

  @media (max-width: 860px) {
    min-height: 100vh;
    height: auto;

    .inner-section {
      flex-direction: column;
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .inner-2-section {
      margin-right: 0;
    }
  }
`;

const ThirdSection = styled.div`
  height: 100vh;
  min-height: 540px;
  background-color: hsl(93, 100%, 98%);
  color: #111;
  display: flex;
  flex-direction: column;
  position: relative;

  max-width: 1380px;
  width: 100%;

  &:before,
  &:after {
    height: 100%;
    content: "";
    position: absolute;
    width: 50vw;
    z-index: -1;
  }

  &:before {
    right: 100%;
    background-color: hsl(96, 71%, 96%);
  }
  &:after {
    left: 100%;
    background-color: hsl(93, 100%, 98%);
  }

  .inner-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 3;
  }

  .inner-2-section {
    margin-left: 140px;
    max-width: 370px;
    font-family: "Nunito Sans";
  }

  @media (max-width: 860px) {
    min-height: 100vh;
    height: auto;

    .inner-section {
      flex-direction: column-reverse;
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .inner-2-section {
      margin-left: 0;
      margin-bottom: 40px;
    }
  }
`;

const FourthSection = styled.div`
  height: 400px;
  background-color: #fffff8;
  color: #111;
  position: relative;

  max-width: 1380px;
  width: 100%;

  &:before,
  &:after {
    height: 100%;
    content: "";
    position: absolute;
    width: 50vw;
    z-index: -1;
  }

  &:before {
    right: 100%;
    background-color: #fffff8;
  }
  &:after {
    left: 100%;
    top: 0;
    background-color: rgb(255, 245, 237);
  }

  .level-1-container {
    position: relative;
    height: 100vh;
    min-height: 540px;
  }

  .level-2-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 3;
    position: relative;
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .about-us {
    max-width: 370px;
    margin-top: 90px;
  }

  .pilot-form {
    background-color: #111;
    max-width: 460px;
    color: white;
    padding: 30px;
    margin-left: 60px;
    margin-top: 60px;
    border-radius: 8px;
  }

  @media (max-width: 860px) {
    min-height: 0;
    height: auto;

    .level-1-container {
      height: auto;
      min-height: 0;
    }

    .level-2-container {
      flex-direction: column;
      align-items: center;
    }

    .pilot-form {
      margin-left: 0;
    }
    .about-us {
      margin-top: 60px;
    }
  }
`;

const TopSection = styled.div`
  padding: 0px 60px 1.35rem;
  margin: 0;
  color: hsla(0, 0%, 0%, 0.8);
  position: relative;

  height: 100vh;
  min-height: 540px;
  background-color: #fffff8;
  color: #111;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  width: 100%;

  &:before,
  &:after {
    height: 100%;
    content: "";
    position: absolute;
    width: 50vw;
    z-index: -1;
  }

  &:before {
    right: 100%;
    background-color: #fffff8;
  }
  &:after {
    left: 100%;
    background-color: rgb(231, 246, 253);
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  h2 {
    margin-top: 31px;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 0;

    line-height: 1.15;
    font-size: 18px;
    font-weight: 600;

    opacity: 0.9;
    max-width: 490px;

    @media (max-width: 600px) {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 90px;
    }
    @media (max-width: 330px) {
      margin-top: 80px;
    }
  }

  h1 {
    line-height: 1.15;
    max-width: 520px;
    padding: 0;
    margin-top: 20px;
    margin-left: 60px;
    margin-right: 60px;
    flex-shrink: 0;
    font-size: 33;
    transition: opacity 0.2s ease;

    @media (max-width: 1040px) {
      flex-shrink: unset;
    }

    @media (max-width: 730px) {
      font-size: 28px;
    }

    @media (max-width: 600px) {
      margin-left: 30px;
      margin-right: 30px;
      font-size: 24px;
      margin-top: -60px;
    }

    @media (max-width: 330px) {
      font-size: 22px;
    }
  }
`;

const StyledSVGContainer = styled.div`
  min-height: 100%;
  width: auto;
  left: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  @media (max-width: 1040px) {
    display: none;
  }
`;

const StyledSVG = styled.svg`
  position: absolute;
  width: auto;
  min-height: 100%;
  right: 0;
  overflow: visible !important;
`;

const BG = () => (
  <StyledSVG
    width="591"
    height="534"
    viewBox="0 0 591 534"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="-138"
      width="717"
      height="784"
    >
      <rect
        x="2.47266"
        y="1.05078"
        width="643"
        height="694"
        transform="rotate(-14 2.47266 1.05078)"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask1)">
      <rect
        x="-706.598"
        y="-141.569"
        width="1324.32"
        height="586.487"
        fill="#FFEEE6"
      />
      <rect
        x="-690.001"
        y="441.663"
        width="1424"
        height="530.75"
        transform="rotate(-20 -690.001 441.663)"
        fill="#F0FCED"
      />
      <rect
        x="-384.143"
        y="881.473"
        width="1370"
        height="575.514"
        transform="rotate(-40 -384.143 881.473)"
        fill="#E7F6FD"
      />
    </g>
  </StyledSVG>
);

const BG2 = () => (
  <StyledSVG
    width="591"
    height="534"
    viewBox="0 0 591 534"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask3"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="130"
      y="-138"
      width="717"
      height="784"
    >
      <rect
        x="132"
        y="4"
        width="572.077"
        height="700"
        transform="rotate(-14 2.47266 1.05078)"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask3)">
      <rect
        x="-706.598"
        y="-141.569"
        width="1324.32"
        height="586.487"
        fill="#D6ECF6"
      />
      <rect
        x="-690.001"
        y="441.663"
        width="1358.16"
        height="530.75"
        transform="rotate(-20 -690.001 441.663)"
        fill="#D6ECF6"
      />
      <rect
        x="-384.143"
        y="881.473"
        width="1324.32"
        height="575.514"
        transform="rotate(-40 -384.143 881.473)"
        fill="#D6ECF6"
      />
    </g>
  </StyledSVG>
);

const BG3 = () => (
  <StyledSVG
    width="591"
    height="534"
    viewBox="0 0 591 534"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask4"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="130"
      y="-138"
      width="717"
      height="784"
    >
      <rect
        x="261"
        y="4"
        width="572.077"
        height="700"
        transform="rotate(-14 2.47266 1.05078)"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask4)">
      <rect
        x="-706.598"
        y="-141.569"
        width="1324.32"
        height="586.487"
        fill="hsl(96, 71%, 96%)"
      />
      <rect
        x="-690.001"
        y="441.663"
        width="1358.16"
        height="530.75"
        transform="rotate(-20 -690.001 441.663)"
        fill="hsl(96, 71%, 96%)"
      />
      <rect
        x="-384.143"
        y="881.473"
        width="1324.32"
        height="575.514"
        transform="rotate(-40 -384.143 881.473)"
        fill="hsl(96, 71%, 96%)"
      />
    </g>
  </StyledSVG>
);

const BG4 = () => (
  <StyledSVG
    width="591"
    height="534"
    viewBox="0 0 591 534"
    fill="none"
    overflow="visible"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask5"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="130"
      y="-138"
      width="717"
      height="784"
    >
      <rect
        x="390"
        y="4"
        width="572.077"
        height="700"
        transform="rotate(-14 2.47266 1.05078)"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask5)">
      <rect
        x="-706.598"
        y="-141.569"
        width="1324.32"
        height="586.487"
        fill="#FFF5ED"
      />
      <rect
        x="-690.001"
        y="441.663"
        width="1358.16"
        height="530.75"
        transform="rotate(-20 -690.001 441.663)"
        fill="#FFF5ED"
      />
      <rect
        x="-384.143"
        y="881.473"
        width="1324.32"
        height="575.514"
        transform="rotate(-40 -384.143 881.473)"
        fill="#FFF5ED"
      />
    </g>
  </StyledSVG>
);

// const OLD_BG = () => (
//   <StyledSVG
//     viewBox="0 0 611 560"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <mask
//       id="mask0"
//       mask-type="alpha"
//       maskUnits="userSpaceOnUse"
//       x="0"
//       y="-182"
//       width="868"
//       height="868"
//     >
//       <rect
//         x="0.310547"
//         y="-8.46677"
//         width="715"
//         height="715"
//         transform="rotate(-14 0.310547 -8.46677)"
//         fill="#C4C4C4"
//       />
//     </mask>
//     <g mask="url(#mask0)">
//       <rect
//         x="-770.752"
//         y="112.213"
//         width="1426"
//         height="631.518"
//         transform="rotate(-10 -770.752 112.213)"
//         fill="#FFEEE6"
//       />
//       <rect
//         x="-745.17"
//         y="466.071"
//         width="1462.44"
//         height="571.5"
//         transform="rotate(-20 -745.17 466.071)"
//         fill="#F0FCED"
//       />
//       <rect
//         x="-432.762"
//         y="871.729"
//         width="1426"
//         height="503"
//         transform="rotate(-40 -432.762 871.729)"
//         fill="#E7F6FD"
//       />
//     </g>
//   </StyledSVG>
// );

// const CompanyLogo = styled.img`
//   max-width: 160px;
//   max-height: 40px;
//   margin-bottom: 0;
//
//   @media (max-width: 600px) {
//     margin-top: 20px;
//     margin-bottom: 20px;
//   }
// `;
//
// const AlumLogo = styled.img`
//   max-width: 160px;
//   max-height: 32px;
//   margin-bottom: 0;
//   margin-left: 50px;
//
//   filter: contrast(0) brightness(120%);
//   @media (max-width: 600px) {
//     margin: 20px;
//     max-height: 27px;
//   }
// `;
//
// const Underline = styled.span`
//   box-shadow: inset 0 -2px 0 0 #fc6990;
// `;
//
// const SectionHeading = styled.div`
//   max-width: 930px;
//   margin: 0 auto;
//
//   text-align: center;
//   padding-top: 30px;
//   padding-bottom: 30px;
//
//   text-transform: uppercase;
//   color: hsla(226, 0%, 50%, 1);
//   font-size: 11px;
//   line-height: 1.5em;
//   font-weight: 600;
//   letter-spacing: 1px;
// `;
const SectionContents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  color: rgba(255, 255, 255, 0.9);

  margin-left: 0;
  justify-content: flex-start;
  background-color: #111111;
  border-radius: 8px;
  max-width: 620px;
`;

// const CompanySection = styled(Section)`
//   background-color: #fdedf2;
//
//   ${SectionContents} {
//     filter: grayscale(100%) opacity(0.7);
//   }
// `;
// const ResearchSection = styled(Section)``;
// const ResearchCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   text-align: center;
//   width: calc(33% - 20px);
//   @media (max-width: 600px) {
//     padding-top: 20px;
//     width: 100%;
//   }
// `;
// ResearchCard.Title = styled.h4``;
// ResearchCard.Description = styled.div`
//   font-size: 14px;
//
//   div {
//     margin-bottom: 10px;
//   }
//
//   a {
//     text-decoration: none;
//     color: #d94c71;
//     font-weight: 500;
//   }
// `;
const AlumSection = styled(Section)`
  color: #111;
  position: relative;
  margin-top: 90px;

  @media (max-width: 600px) {
    padding: 0 30px;
  }
`;

export default IndexPage;
